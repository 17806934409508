import axios from "axios";
import { jwtDecode } from "jwt-decode";

const jwtInterceptor = axios.create({});

jwtInterceptor.interceptors.request.use((config) => {
    let accessToken = localStorage.getItem("accessToken");
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    return config;
});

jwtInterceptor.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.response.status === 401) {
            const refreshToken = localStorage.getItem("refreshToken");
            const accessToken = localStorage.getItem("accessToken");
            if (accessToken && accessToken !== "undefined") {
                const user = jwtDecode(accessToken)?.payload ?? {};
                try {
                    const response = await axios.post(
                        `${process.env.REACT_APP_HOST_CREDENTIAL}/api/Credential/v1/Authorize/RefreshToken`,
                        { userName: user.userName, refreshToken }
                    );
                    if (response?.data?.succeeded === true) {
                        const newToken = response.data.token;
                        error.config.headers[
                            "Authorization"
                        ] = `bearer ${newToken}`;
                        return axios(error.config);
                    } else {
                        window.location.href = "/login";
                    }
                } catch (refreshError) {
                    window.location.href = "/login";
                }
            } else {
                window.location.href = "/login";
            }
        } else {
            return Promise.reject(error);
        }
    }
);

export default jwtInterceptor;
