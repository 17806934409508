import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    CircularProgress,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import { ACTION_TYPE } from "../../constants/constants";
import jwtInterceptor from "../../components/Shared/jwtInterceptor";

const UpsertUser = ({ onClose, userInfo, fetchUsers, itemMenu }) => {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
    });
    const [errors, setErrors] = useState({});
    const [notification, setNotification] = useState({
        message: "",
        severity: "success",
    });

    useEffect(() => {
        if (userInfo) {
            setUser(userInfo);
        }
    }, [userInfo]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        if (validate()) {
            let action = {};
            setLoading(true);
            if (user.encryptedId) {
                action = (itemMenu.menuItems ?? []).find(
                    (i) => i.action === ACTION_TYPE.UPDATE
                );
            } else {
                action = (itemMenu.menuItems ?? []).find(
                    (i) => i.action === ACTION_TYPE.CREATE
                );
            }
            const axiosConfig = {
                method: action.method,
                url: action.api,
                data: user,
            };

            try {
                const result = await jwtInterceptor(axiosConfig);
                if (result.data?.succeeded) {
                    setNotification({
                        message: user.encryptedId
                            ? "User successfully updated."
                            : "User successfully created. Please check the user's email to activate the account.",
                        severity: "success",
                    });
                    setTimeout(() => {
                        onClose();
                        fetchUsers();
                    }, 3000);
                } else {
                    setNotification({
                        message: "Failed to save user.",
                        severity: "error",
                    });
                }
            } catch (error) {
                setNotification({
                    message: "An error occurred.",
                    severity: "error",
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const validate = () => {
        let tempErrors = {};
        tempErrors.userName = user.userName ? "" : "This field is required.";
        tempErrors.email = user.email
            ? /.+@.+\..+/.test(user.email)
                ? ""
                : "Email is not valid."
            : "This field is required.";
        setErrors(tempErrors);
        return Object.values(tempErrors).every((x) => x === "");
    };

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>
                {user.encryptedId ? "Update User" : "Create User"}
            </DialogTitle>
            {notification.message && (
                <Typography
                    variant="body2"
                    color={
                        notification.severity === "success" ? "green" : "red"
                    }
                    sx={{ paddingLeft: 3, paddingRight: 3 }}
                >
                    {notification.message}
                </Typography>
            )}
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    fullWidth
                    value={user.firstName}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    fullWidth
                    value={user.lastName}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    id="userName"
                    name="userName"
                    label="Username"
                    fullWidth
                    value={user.userName}
                    onChange={handleChange}
                    error={!!errors.userName}
                    helperText={errors.userName}
                />
                <TextField
                    margin="dense"
                    id="email"
                    name="email"
                    label="Email"
                    type="email"
                    fullWidth
                    value={user.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                >
                    {loading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpsertUser;
