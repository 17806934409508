import React, { useState, useContext, useEffect, useRef } from "react";
import {
    AppBar,
    Toolbar,
    Tabs,
    Tab,
    Button,
    Box,
    Container,
    ThemeProvider,
    CssBaseline,
} from "@mui/material";
import { connect } from "react-redux";
import logo from "../../assets/images/logo.png";
import { AuthContext } from "./AuthContext";
import { expirePayload, handleLogout } from "../../utils/auth";
import { jwtDecode } from "jwt-decode";
import Login from "../../pages/Login/Login";
import { createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "../../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../../pages/ResetPassword/ResetPassword";
import { setMenu } from "../../redux/actions/auth-action";
import jwtInterceptor from "./jwtInterceptor";

const Layout = ({ children, setMenu, menu }) => {
    const { user, tabState, setTabState } = useContext(AuthContext);
    const [expired, setExpired] = useState(true);
    const tabsMain = ["Home", "Customer", "Check Out", "Reports", "Settings"];
    const tabsMainEnterprise = ["Home", "Customer", "Service", "Settings"];
    const navigate = useNavigate();

    useEffect(() => {
        checkExpired();
    }, []);

    useEffect(() => {
        if (window.location.pathname !== "/login") {
            const fetchMenu = async () => {
                try {
                    const response = await jwtInterceptor.get(
                        `${process.env.REACT_APP_HOST_CREDENTIAL}/api/Credential/v1/Authorize/GetMenu`
                    );
                    console.log("@@@HHHHH menu: ", response?.data?.data);
                    setMenu(response?.data?.data ?? []);
                } catch (error) {
                    console.error("Lỗi khi lấy menu:", error);
                }
            };

            fetchMenu();
        }
    }, []);

    useEffect(() => {
        checkExpired();
    }, [user]);

    const checkExpired = () => {
        if (window.location.pathname === "/login") {
            return;
        }
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken && accessToken !== "undefined") {
            const expiredTmp = expirePayload(jwtDecode(accessToken));
            setExpired(expiredTmp);
            if (expiredTmp) {
                localStorage.removeItem("accessToken");
            }
        } else {
            setExpired(false);
        }
    };

    const handleMainTabChange = (event, newValue) => {
        setTabState((prevState) => ({
            ...prevState,
            mainTab: newValue,
            subTab: 0,
        }));
        handleDefaultSubTab(newValue);
    };

    const handleSubTabChange = (event, newValue) => {
        setTabState((prevState) => ({
            ...prevState,
            subTab: newValue,
        }));
    };

    const renderTabs = () => {
        let tabs = tabsMain;
        if (user.typeName === "Administrator") {
            tabs = tabsMainEnterprise;
        }
        return tabs.map((item) => (
            <Tab sx={{ textTransform: "none" }} label={item} key={item} />
        ));
    };

    const handleDefaultSubTab = (numberMainTab) => {
        console.log(
            "🚀 ~ handleDefaultSubTab ~ user.typeName:",
            user.typeName,
            menu
        );
        if (user.typeName !== "Administrator") {
            if (numberMainTab !== 4) {
                const groups = menu.home;
                const firstGroup = groups[0];
                console.log(
                    "🚀 ~ handleDefaultSubTab ~ firstGroup0:",
                    firstGroup
                );
                navigate(
                    getUrlForLocal(firstGroup.group, firstGroup.menuDefault.api)
                );
            } else {
                navigate("/api/Credential/v1/Authorize/UpdateProfile");
            }
        } else {
            if (numberMainTab !== 3) {
                console.log(
                    "🚀 ~ handleDefaultSubTab ~ numberMainTab:",
                    numberMainTab
                );
                if (numberMainTab !== 2) {
                    const groups = menu.home;
                    console.log("🚀 ~ handleDefaultSubTab ~ groups:", groups);
                    const firstGroup = groups[0];
                    console.log(
                        "🚀 ~ handleDefaultSubTab ~ firstGroup:",
                        firstGroup
                    );
                    console.log(
                        "🚀 ~ handleDefaultSubTab ~ firstGroup1:",
                        firstGroup.menuDefault.api,
                        "----",
                        menu.home
                    );
                    navigate(
                        getUrlForLocal(
                            firstGroup.group,
                            firstGroup.menuDefault.api
                        )
                    );
                } else {
                    const groups = menu?.service;
                    const firstGroup = groups[0];
                    console.log(
                        "🚀 ~ handleDefaultSubTab ~ firstGroup2:",
                        firstGroup.menuDefault.api
                    );
                    navigate(
                        getUrlForLocal(
                            firstGroup.group,
                            firstGroup.menuDefault.api
                        )
                    );
                }
            } else {
                navigate("/api/Credential/v1/Authorize/UpdateProfile");
            }
        }
    };

    const renderSubTabs = (mainTab) => {
        if (user.typeName !== "Administrator") {
            if (mainTab !== 4) {
                return menu?.home?.map((group, index) => (
                    <Tab
                        sx={{ textTransform: "none" }}
                        label={group.group}
                        key={index}
                        onClick={() => {
                            navigate(
                                getUrlForLocal(
                                    group.group,
                                    group.menuDefault.api
                                )
                            );
                        }}
                    />
                ));
            } else {
                return [
                    <Tab
                        sx={{ textTransform: "none" }}
                        label="Profile"
                        key={0}
                        onClick={() => {
                            navigate(
                                "/api/Credential/v1/Authorize/UpdateProfile"
                            );
                        }}
                    />,
                    <Tab
                        sx={{ textTransform: "none" }}
                        label="Change Password"
                        key={1}
                        onClick={() => {
                            navigate("/api/Credential/v1/Authorize/ChangePass");
                        }}
                    />,
                ];
            }
        } else {
            if (mainTab !== 3) {
                if (mainTab !== 2) {
                    console.log("@@@HHHH menu.service: ", menu.service);
                    return menu.home?.map((group, index) => (
                        <Tab
                            sx={{ textTransform: "none" }}
                            label={group.group}
                            key={index}
                            onClick={() => {
                                navigate(
                                    getUrlForLocal(
                                        group.group,
                                        group.menuDefault.api
                                    )
                                );
                            }}
                        />
                    ));
                } else {
                    return menu.service?.map((group, index) => (
                        <Tab
                            sx={{ textTransform: "none" }}
                            label={group.group}
                            key={index}
                            onClick={() => {
                                navigate(
                                    getUrlForLocal(
                                        group.group,
                                        group.menuDefault.api
                                    )
                                );
                            }}
                        />
                    ));
                }
            } else {
                return [
                    <Tab
                        sx={{ textTransform: "none" }}
                        label="Profile"
                        key={0}
                        onClick={() => {
                            navigate(
                                "/api/Credential/v1/Authorize/UpdateProfile"
                            );
                        }}
                    />,
                    <Tab
                        sx={{ textTransform: "none" }}
                        label="Change Password"
                        key={1}
                        onClick={() => {
                            navigate("/api/Credential/v1/Authorize/ChangePass");
                        }}
                    />,
                ];
            }
        }
    };

    const defaultTheme = createTheme();

    const doLogout = async () => {
        await handleLogout();
        setExpired(true);
    };

    const getUrlForLocal = (nameUrl, urlDefault) => {
        switch (nameUrl) {
            case "User Management":
                return "/api/Credential/v1/User/Search";
            case "Enterprise Management":
                return "/api/Services/v1/Enterprise/Search";
            case "Breed Management":
                return "/api/Services/v1/Breed/Search";
            case "Service Management":
                return "/api/Services/v1/Service/Search";
            case "Booking Management":
                return "/Services/v1/Booking/Search";
            case "Service 1":
                return `api/Credential/v1/User/CreateTenant?service=${nameUrl}`;
            case "Service 2":
                return `api/Credential/v1/User/CreateTenant?service=${nameUrl}`;
            case "Service 3":
                return `api/Credential/v1/User/CreateTenant?service=${nameUrl}`;
            case "Service 4":
                return `api/Credential/v1/User/CreateTenant?service=${nameUrl}`;
            case "Service 5":
                return `api/Credential/v1/User/CreateTenant?service=${nameUrl}`;
            default:
                return urlDefault;
        }
    };

    return (
        <>
            {!expired &&
                window.location.pathname !== "/login" &&
                window.location.pathname !==
                    "/api/Credential/v1/Authorize/ForgotPassword" && (
                    <ThemeProvider theme={defaultTheme}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100vh",
                            }}
                        >
                            <CssBaseline />
                            <AppBar
                                position="static"
                                sx={{ backgroundColor: "white" }}
                            >
                                <Toolbar
                                    sx={{
                                        borderBottom: "1px solid #F95D5D",
                                        paddingBottom: 0,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexGrow: 1,
                                        }}
                                    >
                                        <img
                                            src={logo}
                                            alt="Logo"
                                            style={{
                                                height: "40px",
                                                marginRight: "16px",
                                            }}
                                        />
                                        <Tabs
                                            value={tabState.mainTab}
                                            onChange={handleMainTabChange}
                                            textColor="inherit"
                                            indicatorColor="none"
                                            sx={{
                                                "& .MuiTab-root": {
                                                    color: "#F95D5D",
                                                    minWidth: "auto",
                                                    padding: "0 16px",
                                                },
                                                "& .Mui-selected": {
                                                    backgroundColor: "#e0e0e0",
                                                    borderRadius: "4px 4px 0 0",
                                                },
                                            }}
                                        >
                                            {renderTabs()}
                                        </Tabs>
                                    </Box>

                                    <Button
                                        color="inherit"
                                        variant="outlined"
                                        sx={{
                                            borderColor: "#F15A4F",
                                            color: "#F15A4F",
                                            textTransform: "none",
                                        }}
                                        onClick={doLogout}
                                    >
                                        Log Out
                                    </Button>
                                </Toolbar>
                                <Box
                                    sx={{
                                        backgroundColor: "#F15A4F",
                                        display: "flex",
                                        justifyContent: "left",
                                        marginTop: -1,
                                        paddingLeft: 3,
                                    }}
                                >
                                    <Tabs
                                        value={tabState.subTab}
                                        onChange={handleSubTabChange}
                                        textColor="inherit"
                                        indicatorColor="none"
                                        sx={{
                                            "& .MuiTab-root": {
                                                color: "white",
                                                minWidth: "auto",
                                                padding: "0 16px",
                                                "&.Mui-selected": {
                                                    color: "#F15A4F",
                                                    backgroundColor: "white",
                                                    borderRadius: "4px 4px 0 0",
                                                    border: "2px solid #F95D5D",
                                                    borderBottom: "none",
                                                },
                                            },
                                        }}
                                    >
                                        {menu &&
                                            renderSubTabs(tabState.mainTab)}
                                    </Tabs>
                                </Box>
                            </AppBar>
                            <Box
                                component="main"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === "light"
                                            ? theme.palette.grey[100]
                                            : theme.palette.grey[900],
                                    // flexGrow: 1,
                                    height: "100vh",
                                    overflow: "auto",
                                }}
                            >
                                <Toolbar />
                                <Container maxWidth="lg" sx={{ mb: 4 }}>
                                    {children}
                                </Container>
                            </Box>
                        </Box>
                    </ThemeProvider>
                )}
            {(expired || window.location.pathname === "/login") &&
                window.location.pathname !==
                    "/api/Credential/v1/Authorize/ForgotPassword" && (
                    <Login></Login>
                )}
            {expired &&
                window.location.pathname ===
                    "/api/Credential/v1/Authorize/ForgotPassword" && (
                    <ForgotPassword></ForgotPassword>
                )}
            {window.location.pathname ===
                "/api/Credential/v1/Authorize/ResetPassword" && (
                <ResetPassword></ResetPassword>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    menu: state.auth.menu,
});

const mapStateToActions = {
    setMenu,
};

export default connect(mapStateToProps, mapStateToActions)(Layout);
