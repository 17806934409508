import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
} from "@mui/material";
import { ACTION_TYPE } from "../../constants/constants";
import jwtInterceptor from "../../components/Shared/jwtInterceptor";

const UpertEnterprise = ({
    onClose,
    enterpriseInfo,
    fetchEnterprises,
    itemMenu,
}) => {
    const [loading, setLoading] = useState(false);
    const [enterprise, setEnterprise] = useState({
        name: "",
        thumbnail: "",
        description: "",
        statusName: "",
        numberOfBookings: "",
        numberOfRatings: "",
        numberOfVotes: "",
        isVerified: "",
    });

    useEffect(() => {
        if (enterpriseInfo) {
            setEnterprise(enterpriseInfo);
        }
    }, [enterpriseInfo]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEnterprise((prevEnterprise) => ({
            ...prevEnterprise,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        let action = {};
        setLoading(true);
        if (enterprise.encryptedId) {
            action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.UPDATE
            );
        } else {
            action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.CREATE
            );
        }
        const axiosConfig = {
            method: action.method,
            url: action.api,
            data: enterprise,
        };

        await jwtInterceptor(axiosConfig);
        setLoading(false);
        onClose();
        fetchEnterprises();
    };

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                {enterprise.encryptedId
                    ? "Update Enterprise"
                    : "Create Enterprise"}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                margin="dense"
                                id="name"
                                name="name"
                                label="Name"
                                fullWidth
                                value={enterprise.name}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                margin="dense"
                                id="description"
                                name="description"
                                label="Description"
                                fullWidth
                                value={enterprise.description}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                margin="dense"
                                id="statusName"
                                name="statusName"
                                label="Status Name"
                                fullWidth
                                disabled
                                value={enterprise.statusName}
                                // onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            id="numberOfBookings"
                            name="numberOfBookings"
                            label="Number Of Bookings"
                            type="number"
                            fullWidth
                            disabled
                            value={enterprise.numberOfBookings}
                            // onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            id="numberOfRatings"
                            name="numberOfRatings"
                            label="Number Of Ratings"
                            type="number"
                            fullWidth
                            disabled
                            value={enterprise.numberOfRatings}
                            // onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            id="numberOfVotes"
                            name="numberOfVotes"
                            label="Number Of Votes"
                            type="number"
                            fullWidth
                            disabled
                            value={enterprise.numberOfVotes}
                            // onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            id="isVerified"
                            name="isVerified"
                            label="Is Verified"
                            fullWidth
                            disabled
                            value={enterprise.isVerified}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                margin="dense"
                                id="thumbnail"
                                name="thumbnail"
                                label="Thumbnail"
                                fullWidth
                                value={enterprise.thumbnail}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                >
                    {loading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpertEnterprise;
