import React, { useState } from "react";
import { Container, Typography, TextField, Button } from "@mui/material";
import jwtInterceoptor from "../../components/Shared/jwtInterceptor";
import { handleLogout } from "../../utils/auth";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError("New password and confirm password don't match");
            return;
        }
        const axiosConfig = {
            method: "POST",
            url: `${process.env.REACT_APP_HOST_CREDENTIAL}/api/Credential/v1/Authorize/ChangePass`,
            data: {
                passwordOld: currentPassword,
                password: newPassword,
                confirmPassword: confirmPassword,
            },
        };
        const result = await jwtInterceoptor(axiosConfig);

        if (!result?.data?.succeeded) {
            setError(result?.data?.message);
        } else {
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
            setError("");
            await handleLogout();
            navigate("/login");
        }
    };

    return (
        <Container maxWidth="sm">
            <Typography
                variant="h4"
                align="center"
                gutterBottom
                color={"#F15A4F"}
            >
                Change Password
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    margin="normal"
                    type="password"
                    label="Current Password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    required
                />
                <TextField
                    fullWidth
                    margin="normal"
                    type="password"
                    label="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                />
                <TextField
                    fullWidth
                    margin="normal"
                    type="password"
                    label="Confirm New Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
                <div style={{ textAlign: "center" }}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ backgroundColor: "#F15A4F" }}
                    >
                        Change Password
                    </Button>
                </div>
            </form>
        </Container>
    );
};

export default ChangePassword;
