import React, { useState, useEffect, useContext } from "react";
import {
    Container,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    CircularProgress,
    IconButton,
    Box,
    CardMedia,
    TextField,
    TableFooter,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import jwtInterceptor from "../../components/Shared/jwtInterceptor";
import AddIcon from "@mui/icons-material/Add";
import UpsertEnterpriseService from "./UpsertEnterpriseService";
import { connect } from "react-redux";
import { MENU_TYPE, ACTION_TYPE } from "../../constants/constants";
import { AuthContext } from "../../components/Shared/AuthContext";

const EnterpriseSearch = ({
    menu,
    onClose,
    fetchEnterpriseService,
    encryptedServiceRootId,
    itemMenuEnterpriseService,
}) => {
    const { user } = useContext(AuthContext);
    const [itemMenu, setItemMenu] = useState();
    const [enterprises, setEnterprises] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState("ASC");
    const [showCreatePopup, setShowCreatePopup] = useState(false);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);
    const [selectedEnterprise, setSelectedEnterprise] = useState(null);
    const [enterpriseServiceInfo, setEnterpriseServiceInfo] = useState(null);

    const [confirmDeleteEnterprise, setConfirmDeleteEnterprise] =
        useState(null);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    useEffect(() => {
        return () => {
            fetchEnterpriseService();
        };
    }, []);

    useEffect(() => {
        setItemMenu(
            menu?.home?.find((i) => i.group === MENU_TYPE.ENTERPRISE_MANAGEMENT)
        );
    }, [menu]);

    useEffect(() => {
        if (itemMenu) {
            fetchEnterprises();
        }
    }, [page, pageSize, itemMenu, searchText, sortField, sortDirection]);

    const fetchEnterprises = async () => {
        try {
            setLoading(true);
            const action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.SEARCH
            );
            if (action) {
                let axiosConfig = {
                    method: action.method,
                    url: action.api,
                    data: {
                        paging: true,
                        filter: JSON.stringify({
                            keyword: searchText,
                            encryptedServiceRootId,
                            isRegisteredServiceRoot: false,
                        }),
                        sort: sortField
                            ? [{ field: sortField, direction: sortDirection }]
                            : [],
                        pageNum: page,
                        pageSize: pageSize,
                        forAdmin: true,
                    },
                };
                const response = await jwtInterceptor(axiosConfig);
                setEnterprises(response.data.data);
                setTotalPages(response.data.totalRecords);
            }

            setLoading(false);
        } catch (error) {
            console.error("Error fetching enterprises:", error);
            setLoading(false);
        }
    };

    const handleConfirmDelete = (enterprise) => {
        setConfirmDeleteEnterprise(enterprise);
        setShowConfirmPopup(true);
    };

    const handleChangePage = (e, newPage) => {
        if (newPage > 0) {
            setPage(newPage);
        }
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    const handleSearchChange = (event) => {
        setSearchText((prev) => (prev = event.target.value));
        setPage(1);
    };

    const handleShowUpdatePopup = (encryptedId) => {
        const enterprise = enterprises.find(
            (u) => u.encryptedId === encryptedId
        );
        setEnterpriseServiceInfo({
            // userName: "",
            enterpriseName: enterprise.name,
            uptime: "",
            unitPrice: "",
            statusName: "",
            enterpriseEncryptedId: enterprise.encryptedId,
        });
        setShowUpdatePopup(true);
    };

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>Create Enterprise Service</DialogTitle>
            <DialogContent>
                <div>
                    <Container>
                        <Box
                            mb={2}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Typography variant="h4" color={"#F15A4F"}>
                                Enterprise List
                            </Typography>
                        </Box>
                        <Box
                            mb={2}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <TextField
                                label="Search"
                                variant="outlined"
                                value={searchText}
                                onChange={handleSearchChange}
                            />
                        </Box>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Thumbnail</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Status Name</TableCell>
                                        <TableCell>Is Verified</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loading ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={8}
                                                align="center"
                                            >
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        enterprises.map((enterprise, index) => (
                                            <TableRow key={enterprise.id}>
                                                <TableCell>
                                                    {(page - 1) * pageSize +
                                                        index +
                                                        1}
                                                </TableCell>
                                                <TableCell>
                                                    {enterprise.name}
                                                </TableCell>
                                                <TableCell>
                                                    <CardMedia
                                                        component="img"
                                                        width="80"
                                                        image={
                                                            enterprise.thumbnail
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {enterprise.description}
                                                </TableCell>
                                                <TableCell>
                                                    {enterprise.statusName}
                                                </TableCell>
                                                <TableCell>
                                                    {enterprise.isVerified}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        aria-label="add"
                                                        onClick={() => {
                                                            handleShowUpdatePopup(
                                                                enterprise.encryptedId
                                                            );
                                                        }}
                                                        color="primary"
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            count={totalPages}
                                            rowsPerPage={pageSize}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={
                                                handlePageSizeChange
                                            }
                                            rowsPerPageOptions={[
                                                5, 10, 15, 20, 25,
                                            ]}
                                            labelRowsPerPage={
                                                <span>Rows:</span>
                                            }
                                            labelDisplayedRows={({ page }) => {
                                                return `Page: ${page}`;
                                            }}
                                            backIconButtonProps={{
                                                color: "secondary",
                                                disabled: page <= 1,
                                            }}
                                            nextIconButtonProps={{
                                                color: "secondary",
                                            }}
                                            SelectProps={{
                                                inputProps: {
                                                    "aria-label": "page number",
                                                },
                                            }}
                                            showFirstButton={true}
                                            showLastButton={true}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Container>
                    {showUpdatePopup && (
                        <UpsertEnterpriseService
                            enterpriseInfo={selectedEnterprise}
                            onClose={() => setShowUpdatePopup(false)}
                            fetchEnterprises={fetchEnterprises}
                            itemMenu={itemMenuEnterpriseService}
                            enterpriseServiceInfo={enterpriseServiceInfo}
                            encryptedServiceRootId={encryptedServiceRootId}
                        />
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    menu: state.auth.menu,
});

export default connect(mapStateToProps)(EnterpriseSearch);
