import React, { useState, useEffect } from "react";
import {
    Button,
    TextField,
    CircularProgress,
    FormControl,
    Grid,
    Box,
    CardMedia,
    Typography,
} from "@mui/material";
import { ACTION_TYPE } from "../../constants/constants";
import jwtInterceptor from "../../components/Shared/jwtInterceptor";

const UpertEnterpriseTenant = ({ itemMenu, enterpriseId }) => {
    const [loading, setLoading] = useState(false);
    const [thumbnailFile, setThumbnailFile] = useState(null);
    const [enterprise, setEnterprise] = useState({
        name: "",
        thumbnail: "",
        description: "",
        statusName: "",
        numberOfBookings: "",
        numberOfRatings: "",
        numberOfVotes: "",
        isVerified: "",
    });
    const [notification, setNotification] = useState({
        message: "",
        severity: "success",
    });

    useEffect(() => {
        if (enterpriseId && itemMenu) {
            fetEnterprice();
        }
    }, [itemMenu, enterpriseId]);

    const fetEnterprice = async () => {
        let action = {};
        action = (itemMenu.menuItems ?? []).find(
            (i) => i.action === ACTION_TYPE.VIEW
        );
        const axiosConfig = {
            method: action.method,
            url: action.api,
            data: {
                encryptedId: enterpriseId,
                forAdmin: true,
            },
        };
        const result = await jwtInterceptor(axiosConfig);
        setEnterprise(result?.data?.data ?? {});
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEnterprise((prevEnterprise) => ({
            ...prevEnterprise,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        let action = {};
        setLoading(true);
        if (enterprise.encryptedId) {
            action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.UPDATE
            );
        }
        const axiosConfig = {
            method: action.method ?? "POST",
            url: action.api,
            data: {
                ...enterprise,
                encryptedLocationId: enterprise.location?.encryptedId,
            },
        };
        try {
            const result = await jwtInterceptor(axiosConfig);
            if (result.data?.succeeded) {
                setNotification({
                    message: "Enterprise successfully updated.",
                    severity: "success",
                });
            } else {
                setNotification({
                    message: "Failed to save enterprise.",
                    severity: "error",
                });
            }
        } catch (error) {
            setNotification({
                message: "An error occurred.",
                severity: "error",
            });
        } finally {
            setLoading(false);
        }
        fetEnterprice();
    };

    const handleCancel = () => {
        fetEnterprice();
    };

    const handleFileChange = (event) => {
        setThumbnailFile(event.target.files[0]);
    };

    const handleThumbnailSubmit = async () => {
        if (!thumbnailFile) {
            alert("Please select an image file.");
            return;
        }

        const formData = new FormData();
        formData.append("encryptedId", enterprise.encryptedId);
        formData.append("file", thumbnailFile);
        const axiosConfig = {
            method: "POST",
            url: `${process.env.REACT_APP_HOST_CREDENTIAL}/Services/api/Services/v1/Enterprise/UpdateImage`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        try {
            setLoading(true);
            const response = await jwtInterceptor(axiosConfig);

            setEnterprise({
                ...enterprise,
                thumbnail: response?.data?.data?.thumbnail,
            });
        } catch (error) {
            console.error("Error update thumbnail:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {notification.message && (
                <Typography
                    variant="body2"
                    color={
                        notification.severity === "success" ? "green" : "red"
                    }
                    sx={{ paddingLeft: 3, paddingRight: 3 }}
                >
                    {notification.message}
                </Typography>
            )}
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField
                            margin="dense"
                            id="name"
                            name="name"
                            label="Name"
                            fullWidth
                            value={enterprise.name}
                            onChange={handleChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField
                            margin="dense"
                            id="description"
                            name="description"
                            label="Description"
                            fullWidth
                            value={enterprise.description}
                            onChange={handleChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField
                            margin="dense"
                            id="statusName"
                            name="statusName"
                            label="Status Name"
                            fullWidth
                            disabled
                            value={enterprise.statusName}
                            // onChange={handleChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        margin="dense"
                        id="numberOfBookings"
                        name="numberOfBookings"
                        label="Number Of Bookings"
                        type="number"
                        fullWidth
                        disabled
                        value={enterprise.numberOfBookings}
                        // onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        margin="dense"
                        id="numberOfRatings"
                        name="numberOfRatings"
                        label="Number Of Ratings"
                        type="number"
                        fullWidth
                        disabled
                        value={enterprise.numberOfRatings}
                        // onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        margin="dense"
                        id="numberOfVotes"
                        name="numberOfVotes"
                        label="Number Of Votes"
                        type="number"
                        fullWidth
                        disabled
                        value={enterprise.numberOfVotes}
                        // onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        margin="dense"
                        id="isVerified"
                        name="isVerified"
                        label="Is Verified"
                        fullWidth
                        disabled
                        value={enterprise.isVerified}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid container item xs={6} spacing={2}>
                    <Grid item xs={4}>
                        <CardMedia
                            component="img"
                            sx={{ width: 80, height: "auto" }}
                            image={enterprise.thumbnail}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <input
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={handleFileChange}
                            style={{ display: "block", marginBottom: "10px" }}
                        />
                        <Button
                            onClick={handleThumbnailSubmit}
                            color="primary"
                            variant="contained"
                            disabled={loading}
                            style={{ display: "block" }}
                        >
                            {loading ? <CircularProgress size={24} /> : "Save"}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Box
                style={{
                    display: "flex",
                    justifyContent: "center",
                    background: "rgba(0, 0, 0, 0.05)",
                    padding: "10px",
                    borderRadius: "5px",
                }}
            >
                <Button
                    color="secondary"
                    conClick={handleCancel}
                    style={{ marginRight: "10px" }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                >
                    {loading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
            </Box>
        </div>
    );
};

export default UpertEnterpriseTenant;
