import { SET_CURRENT_USER, SET_MENU } from "../types";

export const setCurrentUser = (user) => async (dispatch) => {
    dispatch({
        type: SET_CURRENT_USER,
        payload: { user },
    });
};

export const setMenu = (menu) => async (dispatch) => {
    dispatch({
        type: SET_MENU,
        payload: menu,
    });
};
