import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    CircularProgress,
} from "@mui/material";
import { ACTION_TYPE } from "../../constants/constants";
import jwtInterceptor from "../../components/Shared/jwtInterceptor";

const UpsertBreed = ({ onClose, breedInfo, itemMenu,fetchBreeds }) => {
    const [loading, setLoading] = useState(false);
    const [breed, setBreed] = useState({
        name: "",
        description: "",
    });

    useEffect(() => {
        if (breedInfo) {
            console.log("🚀 ~ UpsertBreed ~ :", breedInfo);
            setBreed(breedInfo);
        }
    }, [breedInfo]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setBreed((prevBreed) => ({
            ...prevBreed,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        let action = {};
        setLoading(true);
        if (breed.encryptedId) {
            action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.UPDATE
            );
        } else {
            action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.CREATE
            );
        }
        const axiosConfig = {
            method: action.method,
            url: action.api,
            data: breed,
        };

        await jwtInterceptor(axiosConfig);
        setLoading(false);
        onClose();
        fetchBreeds();
    };

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>
                {breed.encryptedId ? "Update Breed" : "Create Breed"}
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    fullWidth
                    value={breed.name}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    id="description"
                    name="description"
                    label="Description"
                    fullWidth
                    value={breed.description}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                >
                    {loading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpsertBreed;
