import React, { useState, useEffect } from "react";
import {
    TextField,
    Button,
    Grid,
    Typography,
    Container,
    MenuItem,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import jwtInterceoptor from "../../components/Shared/jwtInterceptor";

const Profile = () => {
    const [error, setError] = useState("");
    const [userProfile, setUserProfile] = useState({
        fullName: "",
        mobilePhone: "",
        birthday: "",
        gender: "",
        address: "",
        avatar: "",
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        statusName: "",
        typeName: "",
        encryptedId: "",
    });

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const axiosConfig = {
                    method: "GET",
                    url: `${process.env.REACT_APP_HOST_CREDENTIAL}/api/Credential/v1/Authorize/GetProfile`,
                };
                const result = await jwtInterceoptor(axiosConfig);
                const userData = result?.data?.data || {};
                setUserProfile(userData);
            } catch (error) {
                console.error("Error fetching user profile:", error);
            }
        };

        fetchUserProfile();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUserProfile({ ...userProfile, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const axiosConfig = {
                method: "POST",
                url: `${process.env.REACT_APP_HOST_CREDENTIAL}/api/Credential/v1/Authorize/UpdateProfile`,
                data: userProfile,
            };
            const result = await jwtInterceoptor(axiosConfig);
        } catch (error) {
            console.error("Error updating user profile:", error);
        }
    };

    const validateMobilePhone = () => {
        const regex = /^[0-9]{10}$/;
        if (!regex.test(userProfile.mobilePhone)) {
            setError({
                ...error,
                mobilePhone:
                    "Mobile phone number must be 10 digits long and contain only numbers.",
            });
        } else {
            setError({
                ...error,
                mobilePhone: "",
            });
        }
    };

    const validateBirthday = () => {
        const selectedDate = new Date(userProfile.birthday);
        const currentDate = new Date();
        if (selectedDate >= currentDate) {
            setError({
                ...error,
                birthday: "Birthday must be in the past.",
            });
        } else {
            setError({
                ...error,
                birthday: "",
            });
        }
    };

    return (
        <Container maxWidth="sm">
            <Typography
                variant="h4"
                align="center"
                gutterBottom
                color={"#F15A4F"}
            >
                Update Profile
            </Typography>
            {/* {error && <Typography color="error">{error}</Typography>} */}
            <Grid item xs={12} md={6}>
                <form
                    className="form"
                    onSubmit={handleSubmit}
                    style={{ padding: "16px", boxSizing: "border-box" }}
                >
                    <TextField
                        fullWidth
                        label="Full Name"
                        name="fullName"
                        value={userProfile.fullName}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Mobile Phone"
                        name="mobilePhone"
                        value={userProfile.mobilePhone}
                        onChange={handleInputChange}
                        margin="normal"
                        error={!!error.mobilePhone}
                        helperText={error.mobilePhone}
                        onBlur={validateMobilePhone}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Birthday"
                                    name="birthday"
                                    value={
                                        dayjs(userProfile.birthday) || dayjs()
                                    }
                                    onChange={(newValue) =>
                                        handleInputChange({
                                            target: {
                                                name: "birthday",
                                                value: newValue,
                                            },
                                        })
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                        />
                                    )}
                                    inputFormat="DD/MM/YYYY"
                                    error={!!error.birthday}
                                    helperText={error.birthday}
                                    onBlur={validateBirthday}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                label="Gender"
                                name="gender"
                                value={userProfile.gender}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                style={{ marginTop: 0 }}
                            >
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                    <TextField
                        fullWidth
                        label="Address"
                        name="address"
                        value={userProfile.address}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    <div style={{ textAlign: "center", marginTop: "16px" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            sx={{ backgroundColor: "#F15A4F" }}
                        >
                            Save
                        </Button>
                    </div>
                </form>
            </Grid>
        </Container>
    );
};

export default Profile;
