import { SET_CURRENT_USER, SET_MENU } from "../types";

const initialState = {
    user: {},
    menu: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                user: action.payload,
            };
        case SET_MENU:
            return {
                ...state,
                menu: action.payload,
            };
        default:
            return state;
    }
}
