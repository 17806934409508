import React, { useState, useEffect } from "react";
import {
    Container,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Box,
    TableFooter,
    TablePagination,
    TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UpsertBreed from "./UpsertBreed";
import { connect } from "react-redux";
import { MENU_TYPE, ACTION_TYPE } from "../../constants/constants";
import jwtInterceptor from "../../components/Shared/jwtInterceptor";

const Breeds = ({ menu }) => {
    const [breeds, setBreeds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState("ASC");
    const [sortDirections, setSortDirections] = useState({
        firstName: null,
        lastName: null,
    });
    const [itemMenu, setItemMenu] = useState();

    const [showCreatePopup, setShowCreatePopup] = useState(false);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);
    const [selectedBreed, setSelectedBreed] = useState(null);
    useEffect(() => {
        setItemMenu(
            menu?.home?.find((i) => i.group === MENU_TYPE.BREED_MANAGEMENT)
        );
    }, [menu]);
    useEffect(() => {
        if (itemMenu) {
            fetchBreeds();
        }
    }, [page, pageSize, itemMenu, searchText, sortField, sortDirection]);

    const fetchBreeds = async () => {
        try {
            setLoading(true);
            const action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.SEARCH
            );
            let axiosConfig = {
                method: action.method,
                url: action.api,
                data: {
                    paging: true,
                    filter: {
                        keyword: searchText,
                    },
                    sort: sortField
                        ? [{ field: sortField, direction: sortDirection }]
                        : [],
                    pageNum: page,
                    pageSize: pageSize,
                },
            };

            const response = await jwtInterceptor(axiosConfig);
            setBreeds(response.data.data);
            console.log("🚀 ~ fetchBreeds ~ response.data:", response.data);
            setTotalPages(response.data.totalRecords);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching Breeds:", error);
            setLoading(false);
        }
    };
    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    const handleShowCreatePopup = () => {
        setShowCreatePopup(true);
    };

    const handleShowUpdatePopup = (encryptedId) => {
        console.log("🚀 ~ handleShowUpdatePopup ~ encryptedId:", encryptedId);
        setSelectedBreed(breeds.find((u) => u.encryptedId === encryptedId));
        setShowUpdatePopup(true);
    };

    const handleChangePage = (e, newPage) => {
        if (newPage > 0) {
            setPage(newPage);
        }
    };
    const handleSearchChange = (event) => {
        setSearchText((prev) => (prev = event.target.value));
        setPage(1);
    };

    return (
        <div>
            <Container>
                <Box mb={2} display="flex" justifyContent="space-between">
                    <Typography variant="h4" color={"#F15A4F"}>
                        Breed List
                    </Typography>
                    {itemMenu?.actions?.includes(ACTION_TYPE.CREATE) && (
                        <Button
                            onClick={handleShowCreatePopup}
                            variant="contained"
                            color="primary"
                            sx={{ backgroundColor: "#F15A4F" }}
                        >
                            Create
                        </Button>
                    )}
                </Box>

                <Box mb={2} display="flex" justifyContent="space-between">
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={searchText}
                        onChange={handleSearchChange}
                    />
                </Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                breeds.map((breed, index) => (
                                    <TableRow key={breed.id}>
                                        <TableCell>
                                            {(page - 1) * pageSize + index + 1}
                                        </TableCell>
                                        <TableCell>{breed.name}</TableCell>
                                        <TableCell>
                                            {breed.description}
                                        </TableCell>
                                        <TableCell>
                                            {itemMenu?.actions?.includes(
                                                ACTION_TYPE.UPDATE
                                            ) && (
                                                <IconButton
                                                    aria-label="edit"
                                                    onClick={() => {
                                                        handleShowUpdatePopup(
                                                            breed.encryptedId
                                                        );
                                                    }}
                                                    color="primary"
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            )}
                                            {itemMenu?.actions?.includes(
                                                ACTION_TYPE.DELETE
                                            ) && (
                                                <IconButton
                                                    aria-label="delete"
                                                    // onClick={() => {
                                                    //     setDeletingUser(user);
                                                    // }}
                                                    color="error"
                                                    // disabled={
                                                    //     user.id === userInfo.id ||
                                                    //     user?.role ===
                                                    //         USER_ROLE_OBJ.super_admin
                                                    // }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    count={totalPages}
                                    rowsPerPage={pageSize}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handlePageSizeChange}
                                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    labelRowsPerPage={<span>Rows:</span>}
                                    labelDisplayedRows={({ page }) => {
                                        return `Page: ${page}`;
                                    }}
                                    backIconButtonProps={{
                                        color: "secondary",
                                        disabled: page <= 1,
                                    }}
                                    nextIconButtonProps={{ color: "secondary" }}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "page number",
                                        },
                                    }}
                                    showFirstButton={true}
                                    showLastButton={true}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Container>
            {showCreatePopup && (
                <UpsertBreed
                    itemMenu={itemMenu}
                    onClose={() => setShowCreatePopup(false)}
                />
            )}
            {showUpdatePopup && (
                <UpsertBreed
                    breedInfo={selectedBreed}
                    itemMenu={itemMenu}
                    fetchBreeds={fetchBreeds}
                    onClose={() => setShowUpdatePopup(false)}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    menu: state.auth.menu,
});

export default connect(mapStateToProps)(Breeds);
