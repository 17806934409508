import React, { useState, useEffect } from "react";
import {
    Container,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Box,
    TextField,
    CardMedia,
    TableFooter,
    TablePagination,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { connect } from "react-redux";
import { MENU_TYPE, ACTION_TYPE } from "../../constants/constants";
import jwtInterceptor from "../../components/Shared/jwtInterceptor";
import UpsertService from "./UpsertService";
import UpsertServicePrice from "./UpsertServicePrice";

const Services = ({ menu }) => {
    console.log("🚀 ~ Services ~ menu:", menu);
    const [itemMenu, setItemMenu] = useState();
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState("ASC");
    const [sortDirections, setSortDirections] = useState({
        Name: null,
    });
    const [showCreatePopup, setShowCreatePopup] = useState(false);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);
    const [showSerivePricePopup, setshowSerivePricePopup] = useState(false);
    const [selectedService, setSelectedService] = useState(null);

    const [confirmDeleteServive, setConfirmDeleteServive] = useState(null);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);

    useEffect(() => {
        setItemMenu(
            menu?.home?.find((i) => i.group === MENU_TYPE.SERVICE_MANAGEMENT)
        );
        console.log(menu);
    }, [menu]);

    useEffect(() => {
        if (itemMenu) {
            fetchServices();
        }
    }, [page, pageSize, itemMenu, sortField, sortDirection, searchText]);

    const fetchServices = async () => {
        try {
            setLoading(true);
            const action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.SEARCH
            );
            let axiosConfig = {
                method: action.method,
                url: action.api,
                data: {
                    paging: true,
                    filter: {
                        keyword: searchText,
                    },
                    sort: sortField
                        ? [{ field: sortField, direction: sortDirection }]
                        : [],
                    pageNum: page,
                    pageSize: pageSize,
                },
            };

            const response = await jwtInterceptor(axiosConfig);
            setServices(response.data.data);
            setTotalPages(response.data.totalRecords);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching users:", error);
            setLoading(false);
        }
    };

    const handleConfirmDelete = (service) => {
        setConfirmDeleteServive(service);
        setShowConfirmPopup(true);
    };

    const handleChangePage = (e, newPage) => {
        if (newPage > 0) {
            setPage(newPage);
        }
    };
    const confirmDelete = async () => {
        try {
            const action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.DELETE
            );
            const axiosConfig = {
                method: action.method,
                url: action.api,
                data: { encryptedId: confirmDeleteServive.encryptedId },
            };

            await jwtInterceptor(axiosConfig);

            fetchServices();
            setShowConfirmPopup(false);
        } catch (error) {
            console.error("Error deleting Service:", error);
        }
    };

    const cancelDelete = () => {
        setShowConfirmPopup(false);
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    const handleSearchChange = (event) => {
        setSearchText((prev) => (prev = event.target.value));
        setPage(1);
    };

    const handleSort = (field) => {
        const newSortDirections = { ...sortDirections };

        if (newSortDirections[field] === null) {
            newSortDirections[field] = "ASC";
        } else if (newSortDirections[field] === "ASC") {
            newSortDirections[field] = "DESC";
        } else {
            newSortDirections[field] = null;
        }

        setSortDirections(newSortDirections);
        setSortField(field);
        setSortDirection(newSortDirections[field]);
        setPage(1);
    };

    const handleShowCreatePopup = () => {
        setShowCreatePopup(true);
    };

    const handleShowUpdatePopup = (encryptedId) => {
        console.log(encryptedId);
        setSelectedService(services.find((u) => u.encryptedId === encryptedId));
        setShowUpdatePopup(true);
    };

    const handleShowServicePricePopup = (encryptedId) => {
        setSelectedService(services.find((u) => u.encryptedId === encryptedId));
        setshowSerivePricePopup(true);
    };

    return (
        <div>
            <Container>
                <Box mb={2} display="flex" justifyContent="space-between">
                    <Typography variant="h4" color={"#F15A4F"}>
                        Service List
                    </Typography>
                    {itemMenu?.actions?.includes(ACTION_TYPE.CREATE) && (
                        <Button
                            onClick={handleShowCreatePopup}
                            variant="contained"
                            color="primary"
                            sx={{ backgroundColor: "#F15A4F" }}
                        >
                            Create
                        </Button>
                    )}
                </Box>

                <Box mb={2} display="flex" justifyContent="space-between">
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={searchText}
                        onChange={handleSearchChange}
                    />
                </Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell onClick={() => handleSort("name")}>
                                    Name
                                    <IconButton>
                                        {sortField === "name" &&
                                        sortDirections.firstName === "ASC" ? (
                                            <ArrowUpwardIcon />
                                        ) : sortField === "name" &&
                                          sortDirections.firstName ===
                                              "DESC" ? (
                                            <ArrowDownwardIcon />
                                        ) : (
                                            <></>
                                        )}
                                    </IconButton>
                                </TableCell>
                                <TableCell>DefaultPrice</TableCell>
                                <TableCell>Thumbnail</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>IsHomeService</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                services.map((service, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {(page - 1) * pageSize + index + 1}
                                        </TableCell>
                                        <TableCell>{service.name}</TableCell>
                                        <TableCell>
                                            {service.defaultPrice}
                                        </TableCell>

                                        <TableCell>
                                            <CardMedia
                                                component="img"
                                                sx={{
                                                    width: 80,
                                                    height: "auto",
                                                }}
                                                image={service.thumbnail}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {service.description}
                                        </TableCell>
                                        <TableCell>
                                            {service.isHomeService == true
                                                ? "Yes"
                                                : "False"}
                                        </TableCell>
                                        <TableCell>
                                            {itemMenu?.actions?.includes(
                                                ACTION_TYPE.UPDATE
                                            ) && (
                                                <IconButton
                                                    aria-label="edit"
                                                    onClick={() => {
                                                        handleShowUpdatePopup(
                                                            service.encryptedId
                                                        );
                                                    }}
                                                    color="primary"
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            )}
                                            {itemMenu?.actions?.includes(
                                                ACTION_TYPE.UPDATE
                                            ) && (
                                                <IconButton
                                                    aria-label="Add Service Price"
                                                    onClick={() => {
                                                        handleShowServicePricePopup(
                                                            service.encryptedId
                                                        );
                                                    }}
                                                    color="primary"
                                                >
                                                    <MonetizationOnIcon />
                                                </IconButton>
                                            )}
                                            {itemMenu?.actions?.includes(
                                                ACTION_TYPE.DELETE
                                            ) && (
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => {
                                                        handleConfirmDelete(
                                                            service
                                                        );
                                                    }}
                                                    color="error"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    count={totalPages}
                                    rowsPerPage={pageSize}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handlePageSizeChange}
                                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    labelRowsPerPage={<span>Rows:</span>}
                                    labelDisplayedRows={({ page }) => {
                                        return `Page: ${page}`;
                                    }}
                                    backIconButtonProps={{
                                        color: "secondary",
                                        disabled: page <= 1,
                                    }}
                                    nextIconButtonProps={{ color: "secondary" }}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "page number",
                                        },
                                    }}
                                    showFirstButton={true}
                                    showLastButton={true}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Container>
            {showCreatePopup && (
                <UpsertService
                    onClose={() => setShowCreatePopup(false)}
                    fetchServices={fetchServices}
                    itemMenu={itemMenu}
                />
            )}
            {showUpdatePopup && (
                <UpsertService
                    serviceInfo={selectedService}
                    onClose={() => setShowUpdatePopup(false)}
                    fetchServices={fetchServices}
                    itemMenu={itemMenu}
                />
            )}
            {showSerivePricePopup && (
                <UpsertServicePrice
                    serviceInfo={selectedService}
                    onClose={() => setshowSerivePricePopup(false)}
                    fetchServices={fetchServices}
                    itemMenu={itemMenu}
                />
            )}
            {showConfirmPopup && (
                <Box
                    position="fixed"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="rgba(0,0,0,0.5)"
                >
                    <Box
                        bgcolor="#fff"
                        p={3}
                        borderRadius="8px"
                        boxShadow={3}
                        textAlign="center"
                    >
                        <Typography variant="h6">
                            Are you sure you want to delete this service?
                        </Typography>
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={confirmDelete}
                            >
                                Yes
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={cancelDelete}
                                style={{ marginLeft: "10px" }}
                            >
                                No
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    menu: state.auth.menu,
});

export default connect(mapStateToProps)(Services);
