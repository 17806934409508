import React, { useState, useEffect, useContext } from "react";
import {
    Container,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    CircularProgress,
    IconButton,
    Box,
    CardMedia,
    TextField,
    TableFooter,
    TablePagination,
} from "@mui/material";
import jwtInterceptor from "../../components/Shared/jwtInterceptor";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UpsertEnterprise from "./UpsertEnterprise";
import { connect } from "react-redux";
import { MENU_TYPE, ACTION_TYPE } from "../../constants/constants";
import { AuthContext } from "../../components/Shared/AuthContext";
import UpertEnterpriseTenant from "./UpsertEnterpriseTenant";

const Enterprises = ({ menu }) => {
    const { user } = useContext(AuthContext);
    const [itemMenu, setItemMenu] = useState();
    const [enterprises, setEnterprises] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState("ASC");
    const [showCreatePopup, setShowCreatePopup] = useState(false);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);
    const [selectedEnterprise, setSelectedEnterprise] = useState(null);

    const [confirmDeleteEnterprise, setConfirmDeleteEnterprise] =
        useState(null);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);

    useEffect(() => {
        setItemMenu(
            menu?.home?.find((i) => i.group === MENU_TYPE.ENTERPRISE_MANAGEMENT)
        );
    }, [menu]);

    useEffect(() => {
        if (itemMenu) {
            fetchEnterprises();
        }
    }, [page, pageSize, itemMenu, searchText, sortField, sortDirection]);

    const fetchEnterprises = async () => {
        try {
            setLoading(true);
            const action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.SEARCH
            );
            if (action) {
                let axiosConfig = {
                    method: action.method,
                    url: action.api,
                    data: {
                        paging: true,
                        filter: searchText,
                        sort: sortField
                            ? [{ field: sortField, direction: sortDirection }]
                            : [],
                        pageNum: page,
                        pageSize: pageSize,
                        forAdmin: true,
                    },
                };
                const response = await jwtInterceptor(axiosConfig);
                setEnterprises(response.data.data);
                setTotalPages(response.data.totalRecords);
            }

            setLoading(false);
        } catch (error) {
            console.error("Error fetching enterprises:", error);
            setLoading(false);
        }
    };

    const handleConfirmDelete = (enterprise) => {
        setConfirmDeleteEnterprise(enterprise);
        setShowConfirmPopup(true);
    };

    const confirmDelete = async () => {
        try {
            const action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.DELETE
            );
            const axiosConfig = {
                method: action.method,
                url: action.api,
                data: { encryptedId: confirmDeleteEnterprise.encryptedId },
            };

            await jwtInterceptor(axiosConfig);

            fetchEnterprises();
            setShowConfirmPopup(false);
        } catch (error) {
            console.error("Error deleting enterprise:", error);
        }
    };

    const cancelDelete = () => {
        setShowConfirmPopup(false);
    };

    const handleChangePage = (e, newPage) => {
        if (newPage > 0) {
            setPage(newPage);
        }
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    const handleSearchChange = (event) => {
        setSearchText((prev) => (prev = event.target.value));
        setPage(1);
    };

    // const handleSort = (field) => {
    //     const newSortDirections = { ...sortDirections };

    //     if (newSortDirections[field] === null) {
    //         newSortDirections[field] = "ASC";
    //     } else if (newSortDirections[field] === "ASC") {
    //         newSortDirections[field] = "DESC";
    //     } else {
    //         newSortDirections[field] = null;
    //     }

    //     setSortDirections(newSortDirections);
    //     setSortField(field);
    //     setSortDirection(newSortDirections[field]);
    //     setPage(1);
    // };

    const handleShowCreatePopup = () => {
        setShowCreatePopup(true);
    };

    const handleShowUpdatePopup = (encryptedId) => {
        setSelectedEnterprise(
            enterprises.find((u) => u.encryptedId === encryptedId)
        );
        setShowUpdatePopup(true);
    };

    return !user?.enterpriseId ? (
        <div>
            <Container>
                <Box mb={2} display="flex" justifyContent="space-between">
                    <Typography variant="h4" color={"#F15A4F"}>
                        Enterprise List
                    </Typography>
                    {itemMenu?.actions?.includes(ACTION_TYPE.CREATE) && (
                        <Button
                            onClick={handleShowCreatePopup}
                            variant="contained"
                            color="primary"
                            sx={{ backgroundColor: "#F15A4F" }}
                        >
                            Create
                        </Button>
                    )}
                </Box>
                <Box mb={2} display="flex" justifyContent="space-between">
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={searchText}
                        onChange={handleSearchChange}
                    />
                </Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Thumbnail</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Status Name</TableCell>
                                <TableCell>Number Of Bookings</TableCell>
                                <TableCell>Number Of Ratings</TableCell>
                                <TableCell>Number Of Votes</TableCell>
                                <TableCell>Is Verified</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                enterprises.map((enterprise, index) => (
                                    <TableRow key={enterprise.id}>
                                        <TableCell>
                                            {(page - 1) * pageSize + index + 1}
                                        </TableCell>
                                        <TableCell>{enterprise.name}</TableCell>
                                        <TableCell>
                                            <CardMedia
                                                component="img"
                                                width="80"
                                                image={enterprise.thumbnail}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {enterprise.description}
                                        </TableCell>
                                        <TableCell>
                                            {enterprise.statusName}
                                        </TableCell>
                                        <TableCell>
                                            {enterprise.numberOfBookings}
                                        </TableCell>
                                        <TableCell>
                                            {enterprise.numberOfRatings}
                                        </TableCell>
                                        <TableCell>
                                            {enterprise.numberOfVotes}
                                        </TableCell>
                                        <TableCell>
                                            {enterprise.isVerified}
                                        </TableCell>
                                        <TableCell>
                                            {itemMenu?.actions?.includes(
                                                ACTION_TYPE.UPDATE
                                            ) && (
                                                <IconButton
                                                    aria-label="edit"
                                                    onClick={() => {
                                                        handleShowUpdatePopup(
                                                            enterprise.encryptedId
                                                        );
                                                    }}
                                                    color="primary"
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            )}
                                            {itemMenu?.actions?.includes(
                                                ACTION_TYPE.DELETE
                                            ) && (
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => {
                                                        handleConfirmDelete(
                                                            enterprise
                                                        );
                                                    }}
                                                    color="error"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    count={totalPages}
                                    rowsPerPage={pageSize}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handlePageSizeChange}
                                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    labelRowsPerPage={<span>Rows:</span>}
                                    labelDisplayedRows={({ page }) => {
                                        return `Page: ${page}`;
                                    }}
                                    backIconButtonProps={{
                                        color: "secondary",
                                        disabled: page <= 1,
                                    }}
                                    nextIconButtonProps={{ color: "secondary" }}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "page number",
                                        },
                                    }}
                                    showFirstButton={true}
                                    showLastButton={true}
                                    //ActionsComponent={TablePaginationActions}
                                    //component={Box}
                                    //sx and classes prop discussed in styling section
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Container>
            {showCreatePopup && (
                <UpsertEnterprise
                    onClose={() => setShowCreatePopup(false)}
                    fetchEnterprises={fetchEnterprises}
                    itemMenu={itemMenu}
                />
            )}
            {showUpdatePopup && (
                <UpsertEnterprise
                    enterpriseInfo={selectedEnterprise}
                    onClose={() => setShowUpdatePopup(false)}
                    fetchEnterprises={fetchEnterprises}
                    itemMenu={itemMenu}
                />
            )}
            {showConfirmPopup && (
                <Box
                    position="fixed"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="rgba(0,0,0,0.5)"
                >
                    <Box
                        bgcolor="#fff"
                        p={3}
                        borderRadius="8px"
                        boxShadow={3}
                        textAlign="center"
                    >
                        <Typography variant="h6">
                            Are you sure you want to delete this enterprise?
                        </Typography>
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={confirmDelete}
                            >
                                Yes
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={cancelDelete}
                                style={{ marginLeft: "10px" }}
                            >
                                No
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </div>
    ) : (
        <div>
            <UpertEnterpriseTenant
                enterpriseInfo={selectedEnterprise}
                fetchEnterprises={fetchEnterprises}
                itemMenu={itemMenu}
                enterpriseId={user?.enterpriseId ?? null}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    menu: state.auth.menu,
});

export default connect(mapStateToProps)(Enterprises);
