import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import UsersPage from "./pages/Users/Users";
import EnterprisesService from "./pages/UsersService/UsersService";
import EnterprisePage from "./pages/Enterprise/Enterprises";
import BreedsPage from "./pages/Breed/Breed";
import Layout from "./components/Shared/Layout";
import { AuthContextProvider } from "./components/Shared/AuthContext";
import Bookings from "./pages/Bookings/Bookings";
import Service from "./pages/Service/Service";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Profile from "./pages/Profile/Profile";

const App = () => {
    return (
        <>
            <AuthContextProvider>
                <Layout>
                    <Routes>
                        <Route path="/" element={<Dashboard />}></Route>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/api/Credential/v1/User/Search"
                            element={<UsersPage />}
                        />
                        <Route
                            path="/api/Services/v1/Enterprise/Search"
                            element={<EnterprisePage />}
                        />
                        <Route
                            path="/api/Services/v1/Breed/Search"
                            element={<BreedsPage />}
                        />
                        <Route
                            path="/api/Services/v1/Service/Search"
                            element={<Service />}
                        />
                        <Route
                            path="/Services/v1/Booking/Search"
                            element={<Bookings />}
                        />
                        <Route
                            path="/api/Credential/v1/Authorize/ChangePass"
                            element={<ChangePassword />}
                        />
                        <Route
                            path="/api/Credential/v1/Authorize/UpdateProfile"
                            element={<Profile />}
                        />
                        <Route
                            path="/api/Credential/v1/Authorize/ForgotPassword"
                            element={<ForgotPassword />}
                        />
                        <Route
                            path="/api/Credential/v1/Authorize/ResetPassword"
                            element={<ResetPassword />}
                        />
                        <Route
                            path="/api/Credential/v1/User/CreateTenant"
                            element={<EnterprisesService />}
                        />
                    </Routes>
                </Layout>
            </AuthContextProvider>
        </>
    );
};

export default App;
