import React, { useState, useEffect } from "react";
import {
    Container,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    CircularProgress,
    IconButton,
    Box,
    TextField,
    TableFooter,
    TablePagination,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UpsertUser from "./UpsertUser";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { connect } from "react-redux";
import { MENU_TYPE, ACTION_TYPE } from "../../constants/constants";
import jwtInterceptor from "../../components/Shared/jwtInterceptor";

const Users = ({ menu }) => {
    const [itemMenu, setItemMenu] = useState();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState("ASC");
    const [sortDirections, setSortDirections] = useState({
        firstName: null,
        lastName: null,
    });
    const [showCreatePopup, setShowCreatePopup] = useState(false);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const [confirmDeleteUser, setConfirmDeleteUser] = useState(null);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const options = ["Individual", "Enterprise", "Administrator"];

    useEffect(() => {
        console.log("@@@HHHH menu000000: ", menu);
        setItemMenu(
            menu?.home.find((i) => i.group === MENU_TYPE.USER_MANAGEMENT)
        );
    }, [menu]);

    useEffect(() => {
        if (itemMenu) {
            fetchUsers();
        }
    }, [
        page,
        pageSize,
        itemMenu,
        searchText,
        sortField,
        sortDirection,
        selectedTypes,
    ]);

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.SEARCH
            );
            let axiosConfig = {
                method: action.method,
                url: action.api,
                data: {
                    paging: true,
                    filter: JSON.stringify({
                        types: selectedTypes,
                        keyword: searchText,
                    }),
                    sort: sortField
                        ? [{ field: sortField, direction: sortDirection }]
                        : [],
                    pageNum: page,
                    pageSize: pageSize,
                },
            };

            const response = await jwtInterceptor(axiosConfig);
            setUsers(response.data.data);
            setTotalPages(response.data.totalRecords);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching users:", error);
            setLoading(false);
        }
    };

    const handleConfirmDelete = (user) => {
        setConfirmDeleteUser(user);
        setShowConfirmPopup(true);
    };

    const confirmDelete = async () => {
        try {
            const action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.DELETE
            );
            const axiosConfig = {
                method: action.method,
                url: action.api,
                data: { encryptedId: confirmDeleteUser.encryptedId },
            };

            await jwtInterceptor(axiosConfig);

            fetchUsers();
            setShowConfirmPopup(false);
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    };

    const cancelDelete = () => {
        setShowConfirmPopup(false);
    };

    const handleChangePage = (e, newPage) => {
        if (newPage > 0) {
            setPage(newPage);
        }
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    const handleSearchChange = (event) => {
        setSearchText((prev) => (prev = event.target.value));
        setPage(1);
    };

    const handleSort = (field) => {
        const newSortDirections = { ...sortDirections };

        if (newSortDirections[field] === null) {
            newSortDirections[field] = "ASC";
        } else if (newSortDirections[field] === "ASC") {
            newSortDirections[field] = "DESC";
        } else {
            newSortDirections[field] = null;
        }

        setSortDirections(newSortDirections);
        setSortField(field);
        setSortDirection(newSortDirections[field]);
        setPage(1);
    };

    const handleShowCreatePopup = () => {
        setShowCreatePopup(true);
    };

    const handleShowUpdatePopup = (encryptedId) => {
        setSelectedUser(users.find((u) => u.encryptedId === encryptedId));
        setShowUpdatePopup(true);
    };

    const handleChange = (event) => {
        setSelectedTypes(event.target.value);
    };

    return (
        <div>
            <Container>
                <Box mb={2} display="flex" justifyContent="space-between">
                    <Typography variant="h4" color={"#F15A4F"}>
                        User List
                    </Typography>
                    {itemMenu?.actions?.includes(ACTION_TYPE.CREATE) && (
                        <Button
                            sx={{ backgroundColor: "#F15A4F" }}
                            onClick={handleShowCreatePopup}
                            variant="contained"
                            color="primary"
                        >
                            Create
                        </Button>
                    )}
                </Box>

                <Box
                    mb={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                label="Search"
                                variant="outlined"
                                value={searchText}
                                onChange={handleSearchChange}
                                style={{ marginRight: 8 }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="multilple-type">
                                    Type
                                </InputLabel>
                                <Select
                                    labelId="multilple-type"
                                    multiple
                                    value={selectedTypes}
                                    onChange={handleChange}
                                    renderValue={(selected) =>
                                        selected.join(", ")
                                    }
                                    label="Type"
                                >
                                    {options.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell
                                    onClick={() => handleSort("firstName")}
                                >
                                    First Name
                                    <IconButton>
                                        {sortField === "firstName" &&
                                        sortDirections.firstName === "ASC" ? (
                                            <ArrowUpwardIcon />
                                        ) : sortField === "firstName" &&
                                          sortDirections.firstName ===
                                              "DESC" ? (
                                            <ArrowDownwardIcon />
                                        ) : (
                                            <></>
                                        )}
                                    </IconButton>
                                </TableCell>
                                <TableCell
                                    onClick={() => handleSort("lastName")}
                                >
                                    Last Name
                                    <IconButton>
                                        {sortField === "lastName" &&
                                        sortDirections.lastName === "ASC" ? (
                                            <ArrowUpwardIcon />
                                        ) : sortField === "lastName" &&
                                          sortDirections.lastName === "DESC" ? (
                                            <ArrowDownwardIcon />
                                        ) : (
                                            <></>
                                        )}
                                    </IconButton>
                                </TableCell>
                                <TableCell>Username</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                users.map((user, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {(page - 1) * pageSize + index + 1}
                                        </TableCell>
                                        <TableCell>{user.firstName}</TableCell>
                                        <TableCell>{user.lastName}</TableCell>
                                        <TableCell>{user.userName}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.statusName}</TableCell>
                                        <TableCell>{user.typeName}</TableCell>
                                        <TableCell>
                                            {itemMenu?.actions?.includes(
                                                ACTION_TYPE.UPDATE
                                            ) && (
                                                <IconButton
                                                    aria-label="edit"
                                                    onClick={() => {
                                                        handleShowUpdatePopup(
                                                            user.encryptedId
                                                        );
                                                    }}
                                                    color="primary"
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            )}
                                            {itemMenu?.actions?.includes(
                                                ACTION_TYPE.DELETE
                                            ) && (
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => {
                                                        handleConfirmDelete(
                                                            user
                                                        );
                                                    }}
                                                    color="error"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    count={totalPages}
                                    rowsPerPage={pageSize}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handlePageSizeChange}
                                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    labelRowsPerPage={<span>Rows:</span>}
                                    labelDisplayedRows={({ page }) => {
                                        return `Page: ${page}`;
                                    }}
                                    backIconButtonProps={{
                                        color: "secondary",
                                        disabled: page <= 1,
                                    }}
                                    nextIconButtonProps={{ color: "secondary" }}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "page number",
                                        },
                                    }}
                                    showFirstButton={true}
                                    showLastButton={true}
                                    //ActionsComponent={TablePaginationActions}
                                    //component={Box}
                                    //sx and classes prop discussed in styling section
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Container>
            {showCreatePopup && (
                <UpsertUser
                    onClose={() => setShowCreatePopup(false)}
                    fetchUsers={fetchUsers}
                    itemMenu={itemMenu}
                />
            )}
            {showUpdatePopup && (
                <UpsertUser
                    userInfo={selectedUser}
                    onClose={() => setShowUpdatePopup(false)}
                    fetchUsers={fetchUsers}
                    itemMenu={itemMenu}
                />
            )}
            {showConfirmPopup && (
                <Box
                    position="fixed"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="rgba(0,0,0,0.5)"
                >
                    <Box
                        bgcolor="#fff"
                        p={3}
                        borderRadius="8px"
                        boxShadow={3}
                        textAlign="center"
                    >
                        <Typography variant="h6">
                            Are you sure you want to delete this user?
                        </Typography>
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={confirmDelete}
                            >
                                Yes
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={cancelDelete}
                                style={{ marginLeft: "10px" }}
                            >
                                No
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    menu: state.auth.menu,
});

export default connect(mapStateToProps)(Users);
