import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    CircularProgress,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
} from "@mui/material";
import { ACTION_TYPE } from "../../constants/constants";
import jwtInterceptor from "../../components/Shared/jwtInterceptor";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const UpsertEnterpriseService = ({
    onClose,
    enterpriseServiceInfo,
    fetchEnterpriseService,
    itemMenu,
    encryptedServiceRootId,
    fetchEnterprises,
}) => {
    const [loading, setLoading] = useState(false);
    const [enterpriseService, setEnterpriseService] = useState({
        userName: "",
        enterpriseName: "",
        uptime: "",
        unitPrice: "",
        statusName: "",
    });
    const [errors, setErrors] = useState({});
    const [notification, setNotification] = useState({
        message: "",
        severity: "success",
    });

    useEffect(() => {
        if (enterpriseServiceInfo) {
            setEnterpriseService(enterpriseServiceInfo);
        }
    }, [enterpriseServiceInfo]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEnterpriseService((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        if (validate()) {
            let action = {};
            setLoading(true);
            let fetch = fetchEnterpriseService;
            if (enterpriseService.encryptedId) {
                action = (itemMenu.menuItems ?? []).find(
                    (i) => i.action === ACTION_TYPE.UPDATE
                );
            } else {
                action = (itemMenu.menuItems ?? []).find(
                    (i) => i.action === ACTION_TYPE.CREATE
                );
                fetch = fetchEnterprises;
            }
            const axiosConfig = {
                method: action.method,
                url: action.api,
                data: {
                    ...enterpriseService,
                    encryptedServiceRootId,
                    encryptedEnterpriseId:
                        enterpriseService.enterpriseEncryptedId,
                },
            };

            try {
                const result = await jwtInterceptor(axiosConfig);
                if (result.data?.succeeded) {
                    setNotification({
                        message: "Upsert successful!",
                        severity: "success",
                    });
                    setTimeout(() => {
                        onClose();
                        fetch();
                    }, 3000);
                } else {
                    setNotification({
                        message: "Failed to save Enterprise Service.",
                        severity: "error",
                    });
                }
            } catch (error) {
                setNotification({
                    message: "An error occurred.",
                    severity: "error",
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const validate = () => {
        let tempErrors = {};
        tempErrors.enterpriseName = enterpriseService.enterpriseName
            ? ""
            : "This field is required.";
        setErrors(tempErrors);
        return Object.values(tempErrors).every((x) => x === "");
    };

    const validateUptime = () => {
        const selectedDate = new Date(enterpriseService.uptime);
        const currentDate = new Date();
        if (selectedDate >= currentDate) {
            setErrors({
                ...errors,
                birthday: "Birthday must be in the past.",
            });
        } else {
            setErrors({
                ...errors,
                birthday: "",
            });
        }
    };

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>
                {enterpriseService.encryptedId
                    ? "Update Enterprise Service"
                    : "Create Enterprise Service"}
            </DialogTitle>
            {notification.message && (
                <Typography
                    variant="body2"
                    color={
                        notification.severity === "success" ? "green" : "red"
                    }
                    sx={{ paddingLeft: 3, paddingRight: 3 }}
                >
                    {notification.message}
                </Typography>
            )}
            <DialogContent>
                <Typography variant="h5" gutterBottom></Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name="enterpriseName"
                            label="Enterprise Name"
                            fullWidth
                            value={enterpriseService.enterpriseName}
                            onChange={handleChange}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Uptime"
                                name="uptime"
                                value={
                                    dayjs(enterpriseService.uptime) || dayjs()
                                }
                                onChange={(newValue) =>
                                    handleChange({
                                        target: {
                                            name: "uptime",
                                            value: newValue,
                                        },
                                    })
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                    />
                                )}
                                inputFormat="DD/MM/YYYY"
                                error={!!errors.uptime}
                                helperText={errors.uptime}
                                onBlur={validateUptime}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Price"
                            name="unitPrice"
                            fullWidth
                            value={enterpriseService.unitPrice}
                            onChange={handleChange}
                            error={!!errors.unitPrice}
                            helperText={errors.unitPrice}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="type-label">Type</FormLabel>
                            <RadioGroup
                                label="type"
                                name="type"
                                aria-labelledby="type-label "
                                row
                                value={enterpriseService.type}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value="Active"
                                    control={<Radio />}
                                    label="Active"
                                />
                                <FormControlLabel
                                    value="Inactive"
                                    control={<Radio />}
                                    label="Inactive"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button
                    sx={{ backgroundColor: "#F15A4F" }}
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                >
                    {loading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpsertEnterpriseService;
