import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, TextField, Button } from "@mui/material";
import axios from "axios";

const ResetPassword = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const emailParam = params.get("email");
        const codeParam = params.get("code");
        if (emailParam && codeParam) {
            setEmail(emailParam);
            setCode(codeParam);
        } else {
            navigate("/login");
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_HOST_CREDENTIAL}/api/Credential/v1/Authorize/ResetPass`,
                {
                    email,
                    code,
                    password,
                    confirmPassword,
                }
            );
            if (response?.data && response?.data?.succeeded === false) {
                const errors = response.data.errors;
                const errorStr = errors.map((i) => i.value).join("\n");
                setError(errorStr);
            } else {
                navigate("/login");
            }
        } catch (error) {
            console.error(error.message);
            setError(error.response.data.message);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <div>
                <Typography component="h1" variant="h5" textAlign={"center"}>
                    Reset Password
                </Typography>
                {error && (
                    <Typography color="error" variant="body1">
                        {error}
                    </Typography>
                )}
                <form onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="New Password"
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="confirmPassword"
                        label="Confirm Password"
                        name="confirmPassword"
                        type="password"
                        autoComplete="new-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                    >
                        Reset Password
                    </Button>
                </form>
            </div>
        </Container>
    );
};

export default ResetPassword;
