import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CardMedia,
    Grid,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from "@mui/material";
import jwtInterceptor from "../../components/Shared/jwtInterceptor";
import { ACTION_TYPE } from "../../constants/constants";
import axios from "axios";

const UpsertService = ({ onClose, serviceInfo, fetchServices, itemMenu }) => {
    const [loading, setLoading] = useState(false);
    const [thumbnailFile, setThumbnailFile] = useState(null);
    const [serviceTypes, setserviceTypes] = useState([]);
    const [selectedserviceType, setSelectedserviceType] = useState('');
    const [service, setService] = useState({
        name: "",
        thumbnail: "",
        species: "",
        isHomeService: 0,
        description: ""
    });

    useEffect(() => {
        const response =  axios.patch(
            `${process.env.REACT_APP_HOST_CREDENTIAL}/api/Services/v1/Code/Search`,
            {
                paging: true,
                codeType: "ServiceTypes",
                sort: [{ field: "sequence", direction: "ASC" }],
                pageNum: 1,
                pageSize: 10,
            }
        ).then(response => {
            console.log("@@@HHH: ", response.data.data);
        setserviceTypes(response.data.data);
          });
        

        if (serviceInfo) {
            console.log("🚀 ~ Upertservice ~ serviceInfo:", serviceInfo);
            setService(serviceInfo);
        }
    }, [serviceInfo]);
   
    const handleChange = (event) => {
        const { name, value } = event.target;
        setService((prevService) => ({
            ...prevService,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        let action = {};
        setLoading(true);
        if (service.encryptedId) {
            action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.UPDATE
            );
        } else {
            action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.CREATE
            );
        }
        const axiosConfig = {
            method: action.method,
            url: action.api,
            data: service,
        };

        await jwtInterceptor(axiosConfig);
        setLoading(false);
        onClose();
        fetchServices();
    };
    const handleFileChange = (event) => {
        setThumbnailFile(event.target.files[0]);
    };

    const handleThumbnailSubmit = async () => {
        if (!thumbnailFile) {
            alert("Please select an image file.");
            return;
        }


        const formData = new FormData();
        formData.append("encryptedId", service.encryptedId);
        formData.append("file", thumbnailFile);
        const axiosConfig = {
            method: "POST",
            url: `${process.env.REACT_APP_HOST_CREDENTIAL}/api/Services/v1/Service/UpdateImage`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        try {
            setLoading(true);
            const response = await jwtInterceptor(axiosConfig);
            console.log(
                "🚀 ~ handleThumbnailSubmit ~ response?.data:",
                response?.data
            );

            setService({
                ...service,
                thumbnail: response?.data?.data?.thumbnail,
            });
        } catch (error) {
            console.error("Lỗi khi cập nhật thumbnail:", error);
        } finally {
            setLoading(false);
            fetchServices();
        }
    };


    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>
                {service.encryptedId ? "Update Service" : "Create Service"}
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    fullWidth
                    value={service.name}
                    onChange={handleChange}
                />
                <FormControl fullWidth>
                    <InputLabel id="dropdown-label">Service Type</InputLabel>
                    <Select
                        labelId="dropdown-label"
                        id="dropdown"
                        name="encryptedTypeId"
                        value={service.encryptedTypeId}
                        onChange={handleChange}
                    >
                        {serviceTypes.map((item, encryptedId) => (
                        <MenuItem key={encryptedId} value={item.encryptedId}>
                            {item.name}
                        </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                    <FormControl  style={{display: "inline-block"}}>
                    <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 80, height: "auto" }} // Đặt chiều cao tự động
                                        image={service.thumbnail}
                                    />
                                </TableCell>
                                <TableCell>
                                <input
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={handleFileChange}
                                    style={{ display: "block", marginBottom: "10px" }}
                                />
                                 </TableCell>
                                <TableCell>
                                <Button
                                    onClick={handleThumbnailSubmit}
                                    color="primary"
                                    variant="contained"
                                    disabled={loading}
                                    style={{ display: "block" }}
                                >
                                    {loading ? <CircularProgress size={24} /> : "Save Image"}
                            </Button>
                                    </TableCell>
                                </TableRow>
                                </TableHead>
                            </Table>
                    </TableContainer>
                  
                 </FormControl>
                {/* <TextField
                    margin="dense"
                    id="thumbnail"
                    name="thumbnail"
                    label="thumbnail"
                    fullWidth
                    value={service.thumbnail}
                    onChange={handleChange}
                /> */}
                <FormControl fullWidth margin="dense">
                    <InputLabel id="status-label">isHomeService</InputLabel>
                    <Select
                        labelId="status-label"
                        id="isHomeService"
                        name="isHomeService"
                        value={service.isHomeService}
                        onChange={handleChange}
                    >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    margin="dense"
                    id="description"
                    name="description"
                    label="description"
                    fullWidth
                    value={service.description}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                >
                    {loading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpsertService;
