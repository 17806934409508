import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import jwtInterceptor from "./jwtInterceptor";
import { connect } from "react-redux";
import { setMenu } from "../../redux/actions/auth-action";

const AuthContext = createContext();

const AuthContextProvider = ({ children, setMenu }) => {
    const [user, setUser] = useState(() => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken && accessToken !== "undefined") {
            return jwtDecode(accessToken)?.payload ?? null;
        }
        return null;
    });

    const [tabState, setTabState] = useState({
        mainTab: 0,
        subTab: 0,
    });

    const navigate = useNavigate();

    useEffect(() => {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    const login = async (payload) => {
        const apiResponse = await axios.post(
            `${process.env.REACT_APP_HOST_CREDENTIAL}/api/Credential/v1/Authorize/SignIn`,
            payload
        );
        const { accessToken, refreshToken } = apiResponse?.data?.data ?? {};
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        if (accessToken && accessToken !== "undefined") {
            fetchMenu();
            const userPayload = jwtDecode(accessToken).payload;
            const getUserResponse = await jwtInterceptor.get(
                `${process.env.REACT_APP_HOST_CREDENTIAL}/api/Credential/v1/Authorize/GetProfile`
            );
            const userInfo = getUserResponse?.data?.data ?? {};
            localStorage.setItem(
                "user",
                JSON.stringify({
                    ...userInfo,
                    enterpriseId: userPayload?.encryptedEnterpriseId,
                })
            );
            setUser({
                ...userInfo,
                enterpriseId: userPayload?.encryptedEnterpriseId,
            });
            navigate("/");
            return null;
        } else {
            return apiResponse?.data?.message;
        }
    };

    const fetchMenu = async () => {
        try {
            const response = await jwtInterceptor.get(
                `${process.env.REACT_APP_HOST_CREDENTIAL}/api/Credential/v1/Authorize/GetMenu`
            );
            setMenu(response?.data?.data ?? []);
        } catch (error) {
            console.error("Error get menu:", error);
        }
    };

    return (
        <AuthContext.Provider value={{ user, login, tabState, setTabState }}>
            {children}
        </AuthContext.Provider>
    );
};

const mapDispatchToProps = {
    setMenu,
};

const ConnectedAuthContextProvider = connect(
    null,
    mapDispatchToProps
)(AuthContextProvider);

export { AuthContext, ConnectedAuthContextProvider as AuthContextProvider };
export default ConnectedAuthContextProvider;
