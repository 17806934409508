const {
    default: jwtInterceoptor,
} = require("../components/Shared/jwtInterceptor");

const expirePayload = (o) => {
    let res = true;

    if (o != null) {
        if (typeof o.exp != "number") {
            return res;
        }

        let a = o.exp * 1000;
        let b = Date.now();
        res = b >= a;
    }

    return res;
};

const handleLogout = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    await jwtInterceoptor.post(
        `${process.env.REACT_APP_HOST_CREDENTIAL}/api/Credential/v1/Authorize/RevokeToken`,
        { token: refreshToken }
    );
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
};

module.exports = {
    expirePayload,
    handleLogout,
};
