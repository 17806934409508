import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    IconButton,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import jwtInterceptor from "../../components/Shared/jwtInterceptor";
import { ACTION_TYPE } from "../../constants/constants";
import axios from "axios";
import { SignalCellularNull } from "@mui/icons-material";

const UpsertServicePrice = ({ onClose, serviceInfo, fetchServices, itemMenu }) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [servicePrices,setServicePrices] =useState([]);
    const [servicePrice,setServicePrice] =useState(
        {
            name: "",
            encryptedServiceId: serviceInfo.encryptedId ,
            unitPrice: 0,
            currency: "",
            description: ""
        }
    );
    const [confirmDeleteServicePrice, setConfirmDeleteServicePrice] = useState(null);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (itemMenu) {
            fetchServicesPrice();
            console.log(servicePrices);
            // if(servicePrices.length == 0)
            // {
            //     servicePrices.push(servicePrice);
            // }
        }
    }, [page, pageSize, itemMenu]);
    const fetchServicesPrice = async () => {
        try {
            setLoading(true);
            let action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.SEARCH
            );
            let axiosConfig = {
                method: action.method,
                url: `${process.env.REACT_APP_HOST_CREDENTIAL}/api/Services/v1/ServicePrice/Search`,
                data: {
                    paging: true,
                    filter: { encryptedServiceId: serviceInfo.encryptedId },
                    sort: [
                        {
                            field: "name",
                            direction: "ASC",
                        },
                    ],
                    pageNum: page,
                    pageSize: pageSize,
                    forAdmin: true,
                },
            };

            const response = await jwtInterceptor(axiosConfig);
            setServicePrices([...response.data.data, {
            name: "",
            encryptedServiceId: serviceInfo.encryptedId ,
            unitPrice: 0,
            currency: "",
            description: "",
            encryptedId: null
        }]);
            setTotalPages(response.data.totalRecords);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching users:", error);
            setLoading(false);
        }
    };
    const handleChange = (e, encryptedId) => {
        const name = e.target.name;
        const value = e.target.value;
        const servicePrice = servicePrices.find(
            (i) => i.encryptedId === encryptedId
        );
        servicePrice[name] = value;
        setServicePrices((prevService) => (prevService = [...servicePrices]));
        //validate();
    };

    const handleUpdateById = async (encryptedId) => {
        if (validate(encryptedId)) {
            let servicePrice = servicePrices.find(i => i.encryptedId === encryptedId);
            let action = {};
            setLoading(true);
        
            const axiosConfig = {
                method: "POST", //action.method,
                url: `${process.env.REACT_APP_HOST_CREDENTIAL}/api/Services/v1/ServicePrice/Update`, //action.api,
                data: servicePrice,
            };

            await jwtInterceptor(axiosConfig);
            setLoading(false);
            onClose();
            fetchServices();
        }
    };

    const handleAddServicePrice = async (encryptedServiceId) => {
        if (validate(null)) {
            let servicePrice = servicePrices.find(i => i.encryptedId === null);
            let action = {};
            setLoading(true);
        
            const axiosConfig = {
                method: "POST", //action.method,
                url: `${process.env.REACT_APP_HOST_CREDENTIAL}/api/Services/v1/ServicePrice/Create`, //action.api,
                data: servicePrice,
            };

            await jwtInterceptor(axiosConfig);
            setLoading(false);
            onClose();
            fetchServices();
        }
    };

    const handleDeleteServicePrice = async () => {
        const encryptedId = confirmDeleteServicePrice.encryptedId;
        let dataDelete ={encryptedId: encryptedId};
        setLoading(true);
       
        const axiosConfig = {
            method: "DELETE", //action.method,
            url: `${process.env.REACT_APP_HOST_CREDENTIAL}/api/Services/v1/ServicePrice/Delete`, //action.api,
            data: dataDelete,
        };

        await jwtInterceptor(axiosConfig);
        setLoading(false);
        onClose();
    };

    const handleConfirmDelete = (servicePrice) => {
        setConfirmDeleteServicePrice(servicePrice);
        setShowConfirmPopup(true);
    };

    const cancelDelete = () => {
        setShowConfirmPopup(false);
    };
    const validate = (encryptedId) => {
        const newErrors = {};
        servicePrices.forEach((servicePrice, index) => {
            if(servicePrice.encryptedId ==  encryptedId)
            {
                if (!servicePrice.name) {
                    newErrors[index] = { name: 'Name is required' };
                  }
                  if (!servicePrice.currency) {
                    newErrors[index] = { currency: 'Currency is required' };
                  }
                  if (!servicePrice.unitPrice) {
                    newErrors[index] = { unitPrice: 'UnitPrice is required'};
                  }
            }
          // Add more validation rules as needed for other fields
        });
        setErrors(newErrors);
        console.log("ERRRRRRRRRRRRRRRRRRRRor: ",newErrors )
        return Object.values(newErrors).every((x) => x === "");
      };
      // Call validate function whenever servicePrices change
   /*  React.useEffect(() => {
        validate(null);
    }, [servicePrices]); */

    return (
        <Dialog open onClose={onClose}>
        <DialogTitle>
           
        </DialogTitle>
        <DialogContent>
        <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell>
                                    Name
                                    <IconButton></IconButton>
                                </TableCell>
                                <TableCell>Currency</TableCell>
                                <TableCell>UnitPrice</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                servicePrices.map((servicePrice, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {(page - 1) * pageSize + index + 1}
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="name"
                                                name="name"
                                                label="Name"
                                                fullWidth
                                                value={servicePrice.name}
                                                onChange={(e) =>
                                                    handleChange(
                                                        e,
                                                        servicePrice.encryptedId
                                                    )
                                                }
                                                error={errors[index]?.name ? true : false}
                                                helperText={errors[index]?.name}
                                            />
                                        </TableCell>
                                        <TableCell>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="currency"
                                            name="currency"
                                            label="currency"
                                            fullWidth
                                            value={servicePrice.currency}
                                            error={errors[index]?.currency ? true : false}
                                                helperText={errors[index]?.currency}
                                            onChange={e => handleChange( e, servicePrice.encryptedId)}
                                        />
                                            </TableCell>
                                        <TableCell>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="unitPrice"
                                            name="unitPrice"
                                            label="Price"
                                            type="number"
                                            error={errors[index]?.unitPrice ? true : false}
                                            helperText={errors[index]?.unitPrice}
                                            fullWidth
                                            value={servicePrice.unitPrice}
                                            onChange={e => handleChange( e, servicePrice.encryptedId)}
                                        />
                                            </TableCell>
                                        <TableCell>
                                        {itemMenu?.actions?.includes(
                                                ACTION_TYPE.CREATE
                                            ) && ( (servicePrice.encryptedId === null)?
                                                <IconButton
                                                    aria-label="Create"
                                                    onClick={() => {
                                                        handleAddServicePrice(
                                                            servicePrice.encryptedServiceId
                                                        );
                                                    }}
                                                    color="primary"
                                                >
                                                    <AddCircleIcon />
                                                </IconButton>: ""
                                            )}
                                            {itemMenu?.actions?.includes(
                                                ACTION_TYPE.UPDATE
                                            ) && ((servicePrice.encryptedId !== null)?
                                                <IconButton
                                                    aria-label="edit"
                                                    onClick={() => {
                                                        handleUpdateById(
                                                            servicePrice.encryptedId
                                                        );
                                                    }}
                                                    color="primary"
                                                >
                                                    <SaveIcon />
                                                </IconButton>: ""
                                            )}
                                            {itemMenu?.actions?.includes(
                                                ACTION_TYPE.DELETE
                                            ) && ( (servicePrice.encryptedId !== null)?
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => {
                                                        handleConfirmDelete(
                                                            servicePrice
                                                        );
                                                    }}
                                                    color="error"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>: ""
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {showConfirmPopup && (
                <Box
                    position="fixed"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="rgba(0,0,0,0.5)"
                >
                    <Box
                        bgcolor="#fff"
                        p={3}
                        borderRadius="8px"
                        boxShadow={3}
                        textAlign="center"
                    >
                        <Typography variant="h6">
                            Are you sure you want to delete this service price?
                        </Typography>
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleDeleteServicePrice}
                            >
                                Yes
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={cancelDelete}
                                style={{ marginLeft: "10px" }}
                            >
                                No
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
                </DialogContent>
                </Dialog>
    );
};

export default UpsertServicePrice;
