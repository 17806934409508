import React, { useState } from "react";
import { Container, Typography, TextField, Button } from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";

const Form = styled("form")(({ theme }) => ({
    width: "100%",
    marginTop: theme.spacing(3),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(3, 0, 2),
}));

const ForgotPassword = () => {
    const [email, setEmail] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Password reset request for:", email);
        const result = await axios.post(
            `${process.env.REACT_APP_HOST_CREDENTIAL}/api/Credential/v1/Authorize/ForgotPass`,
            { email }
        );
        console.log("Password reset request for:", email, result);
        // You can add your own logic here, for example, sending a password reset link to the provided email
    };

    return (
        <Container component="main" maxWidth="xs">
            <Typography component="h1" variant="h5">
                Forgot Password
            </Typography>
            <Form onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <SubmitButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    Submit
                </SubmitButton>
            </Form>
        </Container>
    );
};

export default ForgotPassword;
