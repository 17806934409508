import React, { useState, useEffect } from "react";
import {
    Container,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    CircularProgress,
    IconButton,
    Box,
    TextField,
    TableFooter,
    TablePagination,
    Tooltip,
} from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { connect } from "react-redux";
import { MENU_TYPE, ACTION_TYPE } from "../../constants/constants";
import jwtInterceptor from "../../components/Shared/jwtInterceptor";

const Bookings = ({ menu }) => {
    const [itemMenu, setItemMenu] = useState();
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState("ASC");
    const [sortDirections, setSortDirections] = useState({
        firstName: null,
        lastName: null,
    });

    const [confirmBooking, setConfirmBooking] = useState(null);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);

    useEffect(() => {
        setItemMenu(
            menu?.home?.find((i) => i.group === MENU_TYPE.BOOKIng_MANAGEMENT)
        );
    }, [menu]);

    useEffect(() => {
        if (itemMenu) {
            fetchBookings();
        }
    }, [page, pageSize, itemMenu, searchText, sortField, sortDirection]);

    const fetchBookings = async () => {
        try {
            setLoading(true);
            const action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.SEARCH
            );
            let axiosConfig = {
                method: action.method,
                url: action.api,
                data: {
                    paging: true,
                    filter: {
                        bookingTypes: [0, 1, 2],
                        keyword: searchText,
                    },
                    sort: sortField
                        ? [{ field: sortField, direction: sortDirection }]
                        : [],
                    pageNum: page,
                    pageSize: pageSize,
                },
            };

            const response = await jwtInterceptor(axiosConfig);
            setBookings(response.data.data);
            setTotalPages(response.data.totalRecords);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching bookings:", error);
            setLoading(false);
        }
    };

    const handleConfirmBooking = (booking) => {
        setConfirmBooking(booking);
        setShowConfirmPopup(true);
    };

    const doConfirmBooking = async () => {
        try {
            const action = (itemMenu.menuItems ?? []).find(
                (i) => i.action === ACTION_TYPE.CONFIRM
            );
            const axiosConfig = {
                method: action.method,
                url: action.api,
                data: {
                    encryptedId: confirmBooking.encryptedId,
                    note: confirmBooking.note,
                },
            };

            await jwtInterceptor(axiosConfig);

            fetchBookings();
            setShowConfirmPopup(false);
        } catch (error) {
            console.error("Error deleting booking:", error);
        }
    };

    const cancelBooking = () => {
        setShowConfirmPopup(false);
    };

    const handleChangePage = (e, newPage) => {
        if (newPage > 0) {
            setPage(newPage);
        }
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    const handleSearchChange = (event) => {
        setSearchText((prev) => (prev = event.target.value));
        setPage(1);
    };

    const handleSort = (field) => {
        const newSortDirections = { ...sortDirections };

        if (newSortDirections[field] === null) {
            newSortDirections[field] = "ASC";
        } else if (newSortDirections[field] === "ASC") {
            newSortDirections[field] = "DESC";
        } else {
            newSortDirections[field] = null;
        }

        setSortDirections(newSortDirections);
        setSortField(field);
        setSortDirection(newSortDirections[field]);
        setPage(1);
    };

    const handleChangeConfirmBooking = (event) => {
        const { name, value } = event.target;
        setConfirmBooking((prevBooking) => ({
            ...prevBooking,
            [name]: value,
        }));
    };

    return (
        <div>
            <Container>
                <Box mb={2} display="flex" justifyContent="space-between">
                    <Typography variant="h4" color={"#F15A4F"}>
                        Booking List
                    </Typography>
                </Box>
                <Box mb={2} display="flex" justifyContent="space-between">
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={searchText}
                        onChange={handleSearchChange}
                    />
                </Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell onClick={() => handleSort("name")}>
                                    Name
                                    <IconButton>
                                        {sortField === "name" &&
                                        sortDirections.name === "ASC" ? (
                                            <ArrowUpwardIcon />
                                        ) : sortField === "name" &&
                                          sortDirections.name === "DESC" ? (
                                            <ArrowDownwardIcon />
                                        ) : (
                                            <></>
                                        )}
                                    </IconButton>
                                </TableCell>
                                <TableCell
                                    onClick={() => handleSort("quantity")}
                                >
                                    Quantity
                                    <IconButton>
                                        {sortField === "quantity" &&
                                        sortDirections.quantity === "ASC" ? (
                                            <ArrowUpwardIcon />
                                        ) : sortField === "quantity" &&
                                          sortDirections.quantity === "DESC" ? (
                                            <ArrowDownwardIcon />
                                        ) : (
                                            <></>
                                        )}
                                    </IconButton>
                                </TableCell>
                                <TableCell>Booking Date</TableCell>
                                <TableCell>Booking Status</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                bookings.map((booking, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {(page - 1) * pageSize + index + 1}
                                        </TableCell>
                                        <TableCell>{booking.name}</TableCell>
                                        <TableCell>
                                            {booking.quantity}
                                        </TableCell>
                                        <TableCell>
                                            {booking.bookingDate}
                                        </TableCell>
                                        <TableCell>
                                            {booking.bookingStatus}
                                        </TableCell>
                                        <TableCell>
                                            {itemMenu?.actions?.includes(
                                                ACTION_TYPE.CONFIRM
                                            ) &&
                                                booking.bookingStatus !==
                                                    "Confirmed" && (
                                                    <IconButton
                                                        aria-label="confirm"
                                                        onClick={() => {
                                                            handleConfirmBooking(
                                                                booking
                                                            );
                                                        }}
                                                        color="primary"
                                                    >
                                                        <Tooltip title="Confirm">
                                                            <ConfirmationNumberIcon />
                                                        </Tooltip>
                                                    </IconButton>
                                                )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    count={totalPages}
                                    rowsPerPage={pageSize}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handlePageSizeChange}
                                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    labelRowsPerPage={<span>Rows:</span>}
                                    labelDisplayedRows={({ page }) => {
                                        return `Page: ${page}`;
                                    }}
                                    backIconButtonProps={{
                                        color: "secondary",
                                        disabled: page <= 1,
                                    }}
                                    nextIconButtonProps={{ color: "secondary" }}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "page number",
                                        },
                                    }}
                                    showFirstButton={true}
                                    showLastButton={true}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Container>
            {showConfirmPopup && (
                <Box
                    position="fixed"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="rgba(0,0,0,0.5)"
                >
                    <Box
                        bgcolor="#fff"
                        p={3}
                        borderRadius="8px"
                        boxShadow={3}
                        textAlign="center"
                    >
                        <Typography variant="h6">
                            Are you sure you want to confirm this booking??
                        </Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="note"
                            name="note"
                            label="Note"
                            fullWidth
                            value={confirmBooking.note}
                            onChange={handleChangeConfirmBooking}
                        />
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={doConfirmBooking}
                            >
                                Yes
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={cancelBooking}
                                style={{ marginLeft: "10px" }}
                            >
                                No
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    menu: state.auth.menu,
});

export default connect(mapStateToProps)(Bookings);
